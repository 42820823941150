import { Button, Col, Dropdown, Form, Menu, Modal, Row, Select, Switch, Tabs, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import moment from "moment";
import SvgPen from "src/assets/svg/SvgPen";
import styled from "styled-components";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import { dataOrg } from "src/constants";
import { localGetAccount } from "src/utils/localStorage";
import FormItem from "antd/lib/form/FormItem";
import { debounce } from "lodash";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
const { Item } = Form;
const { Option } = Select;
const CreateOperationModal = ({ visible = true, type = "PTW" as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const [form] = Form.useForm();
	const [status, setStatus] = useState<boolean>(true);
	const [isSupper, setIsSupper] = useState<boolean>(false);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [user, setUser] = useState<any>([]);
	const [listUser, setListUser] = useState([]);
	const { stateWarehouses } = useSelector((e: AppState) => e.globalReducer);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				let USER_INFO: any = localGetAccount();
				USER_INFO = JSON.parse(USER_INFO);
				console.log("USER_INFO", USER_INFO);
				loadUser(USER_INFO.user.user_code);
				form.setFieldsValue({
					receive_user_by: `${USER_INFO.user.user_code}`
				});
				setUser(USER_INFO);
				setLoading(true);
				// const res: any = await _apiNotSaga.getApps({ limit: 999, status: "A" });
				// setDataApp(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []); //eslint-disable-line

	const onSubmit = async (val: any) => {
		const params = {
			...val
		};
		setLoading(true);
		try {
			params.operation_type = type;
			params.wh_to_id = params.wh_to_id ? params.wh_to_id : params.wh_from_id;
			params.wh_from_id = val.wh_from_id ? val.wh_from_id : val.wh_to_id;
			const res: any = await _apiNotSaga.createOperationWH(params);
			if (!res.success) {
				setLoading(false);
				notifyError(res.message);
				return;
			}
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
			console.log("first", window.location);
			const currentUrl = window.location.origin; // Get the current URL
			const newPath = "/chi-tiet-xnk/" + res.data.insertId; // Define the new relative path
			const newUrl = `${currentUrl}${newPath}`; // Combine the current URL with the new path
			window.open(newUrl, "_blank", "noopener,noreferrer");
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const loadUser = async (q: any) => {
		if (!q || q === "") return;
		const res: any = await _apiNotSaga.getUsers({ limit: 999, status: "A", q });
		setListUser(res.data);
		// dispatch(fetchUserFromTicket({ do_get_list: true, user_erp_code: q }));
	};

	return (
		<>
			<Modal
				open={visible}
				width={1000}
				onCancel={onCancel}
				// maskClosable={false}
				title={`Tạo mới mã xuất kho`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm1"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Tạo mới</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm1" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<FormSelect
									name="wh_from_id"
									label="Kho xuất"
									dataSource={user.warehouses || []}
									placeholder="Chọn kho xuất"
									slOption={{
										name: "ws_name",
										value: "id"
									}}
									required={true}
									// disabled={disableAddress}
									// onChange={onChangePartnerAddress}
									showSearch
									optionFilterProp="children"
								/>
							</Col>
							<Col xs={24} md={12}>
								{(type === "PTW" || type === "WTD") ? (
									<Form.Item style={{ display: "none" }} name="to_wh_id" />
								) : (
									<FormSelect
										name="wh_to_id"
										label="Kho nhập"
										dataSource={stateWarehouses?.data || []}
										placeholder="Chọn kho nhập"
										slOption={{
											name: "ws_name",
											value: "id"
										}}
										required={true}
										// disabled={disableAddress}
										// onChange={onChangePartnerAddress}
										showSearch
										optionFilterProp="children"
									/>
								)}
							</Col>
							<Col xs={24} md={12}>
								<Item
									label={"Nhân viên tiếp nhận"}
									id={"txt_assignee_name"}
									name={"receive_user_by"}
									className=""
									required={true}
								>
									<Select
										allowClear
										showSearch
										id={"txt_assignee_code"}
										style={{ width: "100%" }}
										placeholder="Vui lòng nhập mã nhân viên"
										// onChange={(value, item) => {
										// 	let user = listUser?.find((i) => i.user_code === item?.key || "");
										// }}
										filterOption={false}
										onSearch={debounce(loadUser, 500)}
									>
										{listUser &&
											listUser.length > 0 &&
											listUser?.map((item: any, idx) => {
												return (
													<Option
														value={`${item.user_code}`}
														key={item.user_code}
													>{`${item.user_code} - ${item.full_name}`}</Option>
												);
											})}
									</Select>
								</Item>
							</Col>
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default CreateOperationModal;
