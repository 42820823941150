import { Button, Dropdown, Menu, Switch } from "antd";
import moment from "moment";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgPen from "src/assets/svg/SvgPen";
import styled from "styled-components";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({ updateStatus, getDetail }: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "Ngành nghề",
			key: "career_name",
			width: 150,
			render: (record: any, index: number) => {
				return (
					<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.career_name}</div>
				);
			}
		},
		{
			title: "Khối",
			width: 140,
			key: "department_block_name",
			render: (record: any, index: number) => {
				return (
					<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
						{record?.department_block_name}
					</div>
				);
			}
		},

		{
			title: "Ngày tạo",
			width: 150,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
							{record?.create_user}
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày tạo:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.created_at).format("DD/MM/YYYY HH:mm")}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày cập nhật:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.updated_at).format("DD/MM/YYYY HH:mm")}
							</span>
						</div>
					</div>
				);
			}
		},

		{
			title: "TT hoạt động",
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 150,
			render: (v: string, r: any) => (
				<div className="flex justify-center">
					<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status, "status")} />
				</div>
			)
		},

		{
			title: "Thao tác",
			dataIndex: "action",
			key: "action",
			align: "center",
			width: 100,
			fixed: "right",
			render: (v: string, r: any) => {
				return (
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(r)} trigger={["hover"]} placement="bottomRight" arrow>
							{/* <Dropdown 
						menu={{ items: items(r) }} 
						overlayClassName="dropdown-css" trigger={["hover"]} placement="bottomRight" arrow> */}
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
