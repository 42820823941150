import { Form, Input, Select, Button, InputNumber, Col, Row, Radio } from "antd";
import "./style.scss";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import FormSelect from "src/components/form/FormSelect";
import { AppState } from "src/types";
import { useSelector } from "react-redux";
const { Option } = Select;
const SenderInfo = ({ form }: any) => {
	// const [form] = Form.useForm();
	const [partnerType, setPartnerType] = useState(1);
	const [partnerList, setPartnerList] = useState<any[]>([]);
	const [partnerAddress, setPartnerAddress] = useState<any[]>([]);
	const [districts, setDistricts] = useState<any[]>([]);
	const [wards, setWards] = useState<any[]>([]);
	const [receiverProvinceId, setReceiverProvinceId] = useState(0);
	const [receiverDistrictId, setReceiverDistrictId] = useState(0);
	const { stateProvinces } = useSelector((e: AppState) => e.globalReducer);
	useEffect(() => {
		const getPartnerList = async () => {
			try {
				const partners: any = await _apiNotSaga.getPartners({});
				if (partners.success) {
					setPartnerList(partners.data);
				}
			} catch (error) {
				console.log(error);
			}
		};
		getPartnerList();
		return () => {
			setPartnerList([]);
		};
	}, []);

	const onChangePartner = async (e: any) => {
		// form.setFieldsValue({ r_ward_id: undefined, r_ward_name: undefined, r_district_name: districts.find((item:any) => item.id === e)?.district_name });
		const partnerAddress: any = await _apiNotSaga.getPartnerAddresses(e, {});
		if (partnerAddress.success) {
			setPartnerAddress(partnerAddress.data);
		}
	};
	const onChangePartnerAddress = async (e: any) => {
		const _sender_info = partnerAddress.find((item: any) => item.id === e);
		console.log("_sender_info", _sender_info);
		form.setFieldsValue({
			s_ward_id: _sender_info.ward_id,
			s_ward_name: _sender_info.ward_name,
			s_district_name: _sender_info.district_name,
			s_district_id: _sender_info.district_id,
			s_province_name: _sender_info.province_name,
			s_province_id: _sender_info.province_id,
			s_address: _sender_info.address,
			s_name: _sender_info.name,
			s_phone: _sender_info.phone
		});
	};
	const onChangePartnerType = ({ target: { value } }: any) => {
		console.log("radio1 checked", value);
		setPartnerType(value);
	};
	const onChangeProvince = async (e: any) => {
		form.setFieldsValue({
			s_province_name: (stateProvinces?.data || []).find((item: any) => item.id === e)?.province_name,
			s_district_id: undefined,
			s_ward_id: undefined,
			s_district_name: undefined,
			s_ward_name: undefined
		});
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			setDistricts(district.data);
		}
	};
	const onChangeDistrict = async (e: any) => {
		form.setFieldsValue({
			s_ward_id: undefined,
			s_ward_name: undefined,
			s_district_name: districts.find((item: any) => item.id === e)?.district_name
		});
		const ward: any = await _apiNotSaga.getWards({ district_id: e });
		if (ward.success) {
			setWards(ward.data);
		}
	};
	return (
		<div>
			<h3 style={{ fontWeight: 600 }}>Thông tin người gửi</h3>
			<Radio.Group onChange={onChangePartnerType} defaultValue={partnerType}>
				<Radio value="1">Đối tác</Radio>
				<Radio value="2">Khách lẻ</Radio>
			</Radio.Group>
			{partnerType == 1 ? (
				<div>
					<FormSelect
						name="sender_id"
						label="Đối tác"
						dataSource={partnerList || []}
						placeholder="Chọn tỉnh thành"
						slOption={{
							name: "partner_name",
							value: "id"
						}}
						required={true}
						// disabled={disableAddress}
						onChange={onChangePartner}
						showSearch
						optionFilterProp="children"
					/>
					<FormSelect
						name="partner_address_id"
						label="Địa chỉ"
						dataSource={partnerAddress || []}
						placeholder="Chọn địa chỉ"
						slOption={{
							name: "sub_name",
							value: "id"
						}}
						required={true}
						// disabled={disableAddress}
						onChange={onChangePartnerAddress}
						showSearch
						optionFilterProp="children"
					/>

					<Form.Item style={{ display: "none" }} name="s_name" />
					<Form.Item style={{ display: "none" }} name="s_phone" />
					<Form.Item style={{ display: "none" }} name="s_address" />
					<Form.Item style={{ display: "none" }} name="s_province_id" />
					<Form.Item style={{ display: "none" }} name="s_district_id" />
					<Form.Item style={{ display: "none" }} name="s_ward_id" />
					<Form.Item style={{ display: "none" }} name="s_province_name" />
					<Form.Item style={{ display: "none" }} name="s_district_name" />
					<Form.Item style={{ display: "none" }} name="s_ward_name" />
				</div>
			) : (
				<div>
					<h3 style={{ fontWeight: 600 }}>Thông tin người nhận</h3>
					<Row gutter={[12, 12]}>
						<Col md={24} xs={24} sm={24}>
							<Form.Item
								label="Họ tên"
								name="s_name"
								rules={[{ required: true, message: "Vui lòng nhập họ tên người gửi" }]}
							>
								<Input placeholder="Nhập họ tên người gửi" className="w-full" />
							</Form.Item>
						</Col>
						<Col md={12} xs={12} sm={12} style={{ paddingLeft: 8 }}>
							<Form.Item
								label="Số điện thoại"
								name="s_phone"
								rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}
							>
								<Input placeholder="Nhập số điện thoại người gửi" className="w-full" />
							</Form.Item>
						</Col>
						<Col md={12} xs={12} sm={12} style={{ paddingTop: 2 }}>
							<Form.Item label="Số điện thoại 2" name="s_phone_2">
								<Input placeholder="Nhập số điện thoại 2" className="w-full" />
							</Form.Item>
						</Col>
					</Row>

					<div className="flex">
						<Form.Item style={{ display: "none" }} name="s_province_name" />
						<Form.Item style={{ display: "none" }} name="s_district_name" />
						<Form.Item style={{ display: "none" }} name="s_ward_name" />
						<Col md={8} xs={8} sm={8} style={{ paddingLeft: 0 }}>
							<FormSelect
								name="s_province_id"
								label="Tỉnh thành"
								dataSource={stateProvinces?.data || []}
								placeholder="Chọn tỉnh thành"
								slOption={{
									name: "province_name",
									value: "id"
								}}
								required={true}
								// disabled={disableAddress}
								onChange={onChangeProvince}
								showSearch
								optionFilterProp="children"
							/>
						</Col>
						<Col md={8} xs={8} sm={8}>
							<FormSelect
								name="s_district_id"
								label="Quận huyện"
								dataSource={districts}
								placeholder="Chọn quận huyện"
								slOption={{
									name: "district_name",
									value: "id"
								}}
								required={true}
								// required={!disableAddress}
								// disabled={disableAddress}
								onChange={onChangeDistrict}
								showSearch
								optionFilterProp="children"
							/>
						</Col>
						<Col md={8} xs={8} sm={8} style={{ paddingRight: 0 }}>
							<FormSelect
								name="s_ward_id"
								label="Phường xã"
								dataSource={wards}
								placeholder="Chọn phường xã"
								slOption={{
									name: "ward_name",
									value: "id"
								}}
								required={true}
								// required={!disableAddress}
								// disabled={disableAddress}
								onChange={(e) => {
									form.setFieldsValue({
										s_ward_name: (wards || []).find((item: any) => item.id === e)?.ward_name
									});
								}}
								showSearch
								optionFilterProp="children"
							/>
						</Col>
					</div>

					<Form.Item label="Địa chỉ" name="s_address" rules={[{ required: true, message: "Vui lòng nhập địa chỉ" }]}>
						<Input placeholder="Nhập địa chỉ, VD: 28/23/22 Nguyễn Thị Nhỏ" className="w-full" />
					</Form.Item>
				</div>
			)}

			{/* <Form.Item
				label="Hãng hàng không"
				name="airline"
				rules={[{ required: true, message: "Vui lòng chọn hãng hàng không" }]}
			>
				<Select placeholder="Chọn hãng hàng không" className="w-full">
					<Option value="VJ">VJ - Vietnam Airline</Option>
				</Select>
			</Form.Item> */}
		</div>
	);
};

export default SenderInfo;
