import { Button, Dropdown, Menu, Switch, Tag } from "antd";
import moment from "moment";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgPen from "src/assets/svg/SvgPen";
import { createUser, userInfo } from "src/components/commonRender/renderData";
import { CND_URL } from "src/services/api/config";
import styled from "styled-components";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({ updateStatus, getDetail,onOpenImageOverlay }: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "ID",
			key: "user_code",
			fixed: "left",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-blue-600 text-[13px] font-normal  underline leading-snug">#{record?.id}</div>
						<Tag color="success">{record?.status === "A" ? "Hoạt động" : "Ẩn"}</Tag>
					</div>
				);
			}
		},
		{
			title: "Hình ảnh",
			dataIndex: "link",
			key: "link",
			width: "15%",
			render: (link: string, record: any, index: number) => {
				return (
					<div style={{ textAlign: "center" }}>
						{record.level !== 2 && link !== "undefined" && link !== "" ? (
							<img
								src={`${CND_URL}/${link}`}
								alt=""
								style={{ maxWidth: "36px", objectFit: "cover", cursor: "pointer" }}
								onClick={() => onOpenImageOverlay(link)}
							/>
						) : null}
					</div>
				);
			}
		},
		{
			title: "Tên banner",
			dataIndex: "banner_name",
			key: "banner_name"
		},

		{
			title: "Thời gian hiển thị",
			key: "user_type_name",
			width: 250,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.phongban}</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày bắt đầu:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.start_at).format("DD/MM/YYYY HH:mm")}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày kết thúc:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.end_at).format("DD/MM/YYYY HH:mm")}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Người tạo",
			key: "user_type_name",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
							{record?.created_by}
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày tạo:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.created_at).format('DD/MM/YYYY')}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngày cập nhật:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.updated_at).format('DD/MM/YYYY')}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Vị trí",
			key: "pos",
			dataIndex: "pos",
			width: 150,
			
		},
		// {
		// 	title: "TT hoạt động",
		// 	dataIndex: "chitieu",
		// 	key: "chitieu",
		// 	align: "center"
		// },

		{
			title: "Thao tác",
			dataIndex: "action",
			key: "action",
			align: "center",
			width: 100,
			fixed: "right",
			render: (v: string, r: any) => {
				return (
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(r)} trigger={["hover"]} placement="bottomRight" arrow>
							{/* <Dropdown 
						menu={{ items: items(r) }} 
						overlayClassName="dropdown-css" trigger={["hover"]} placement="bottomRight" arrow> */}
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
