export const API_LOGIN = "auth/sign-in";
export const API_LOGIN_GOOGLE = "auth/signin/google";
export const API_REGISTER = "auth/signup";

export const API_USER_SYSTEM = "user-system";
export const API_USER_PRIVILEGE = "usergroup-privilege";
// export const API_BANNER = "banners";
export const API_STICKER = "stickers";
export const API_PROMOTION_ACCESSORY = "promotion-accessories";
export const API_META_IMAGE = "meta-image";
export const API_THUMBNAIL = "thumbnail";
export const API_SEARCH_ON_ORDER = "search-on-order";
export const API_ICON = "icon";
export const API_FLASH_SALES = "flash-sales";
export const API_HISTORY = "history";
export const API_REVIEWS_COMMENTS = "reviews-comments";
export const API_SHIPPING_FEE = "shipping-fees";
export const API_RESPONSE = "response";
export const API_TRADEIN = "tradein-programs";
export const API_STORES = "warehouses";
export const API_CATEGORY = "category";
export const API_CATALOG = "catalog";
export const API_CUSTOMER = "customers";
export const API_REPAIR_MANAGEMENT = "repair-categories";
export const API_REPAIR_REGULATION = "repair-rules";
export const API_ORDERS = "orders";
export const API_ORDER_STATUS = "order-status";
export const API_STATUS = "status";
export const API_PAYMENT_METHOD = "payment";
//========== PRODUCT ============//
export const API_PRODUCTS = "products";
export const API_PARENT = "parents";
export const API_DELETE_IMAGES = "delete-images";
export const API_PRODUCT_FEATURES = "product-features";
export const API_TRANSPORT_UNIT = "shippings";
export const API_UPLOAD_IMAGES = "upload-images";
export const API_UPLOAD_META_IMAGE = "upload-meta-image";
export const API_UPLOAD_THUMBNAIL = "upload-thumbnail";
export const API_UPLOADS = "uploads";
export const API_UPLOAD_ICON = "upload-icon";
export const API_RESPONSE_REVIEWS_COMMENTS = "response-reviews-comments";
export const API_RESTRICTED_KEYWORDS = "restricted-keywords";
export const API_HOMEPAGE_CONFIGURE = "homepage-configure";
export const API_OLD_RECEIPTS = "old-receipts";
export const API_TRADEIN_PROGRAMS = "tradein-programs";
export const API_VALUATION_BILLS = "tradein-programs/valuation-bills";
export const API_DISCOUNT_PROGRAMS = "discount-programs";
export const API_BACK_LOG = "back-log";
// export const API_PAGES = "pages";
export const API_PAGE_DETAILS = "page-details";
export const API_UPDATE_STATUS = "update-status";
export const API_VALUES = "values";
export const API_VALUE = "value";
export const API_UPDATE_POSITION = "update-position";
export const API_CATALOGS = "catalogs";
export const API_LOCATE = "locators";
export const API_CITY = "cities";
export const API_CITY_ID = "city_id";
export const API_DISTRICT = "districts";
export const API_DISTRICT_ID = "district_id";
export const API_WARD = "wards";
export const API_ALL = "all";
export const API_COMMENTS = "comments";
export const API_REVIEWS = "reviews";
export const API_SYNC = "sync";
export const API_FUNCTIONS = "functions";
export const API_CALCULATION = "calculation";
export const API_INSTALLMENT = "installment";
export const API_PRICE_LEVEL = "price-level";
export const API_PROMOTIONS = "promotions";
export const API_COUPON = "coupon";
export const API_CHECK = "check";
export const API_PRODUCT_PREVIEW = "product-preview";
export const API_SHOP = "seller-platforms";
export const API_SCHEDULERS = "schedulers";
export const API_CRON_FUNCTIONS = "cron-functions";
export const API_TEST = "web-tester";
export const API_SUPPLIERS = "suppliers";
//vehicle-group
export const API_VEHICLE_GROUP = "vehicle-group";
//========= ATTRIBUTES ===========//
export const API_ATTRIBUTES_LIST = "attributes";

//========= MODULE FUNCTION ======//
export const API_MODULE_FUNCTIONS = "functs";

export const API_IMPORT = "import-goods";
export const API_SHIPPING = "shipping-units";

//========= LOCATORS ===========//
export const API_PROVINCES = "locations/provinces";
export const API_DISTRICTS = "locations/districts";
export const API_WARDS = "locations/wards";
// ======== SUPPLIES MANAGEMENT =====//
export const API_SUPPLIES_MANAGEMENT = "repair-supplies";
export const API_VEHICLE_TYPE = "vehicle-type";
export const API_VEHICLES = "vehicles";
export const API_ROUTING_GROUP = "routing_groups";
export const API_DRIVERS = "drivers";
export const API_ROUTES = "routing_roads";
export const API_WAREHOUSES = "warehouses";
export const API_ROLES = "roles";
export const API_ENUM = "enum";
export const API_ROAD_COST_MANAGEMENT = "cost-of-tolls";
export const API_ROAD_COST_STATION = "station-query";
export const API_PRICE_OFF_TYPE = "cost-types";
export const API_MAINTENANCES = "maintenances";
export const API_PRICE_OFF_CATEGORIES = "price-off-category";
export const API_ERP = "erp";
export const API_PARTNER = "repair-partners";
export const API_VEHICLE_DIARY = "vehicle-diary";
export const API_MODULE_FUNT = "funct";
export const API_MODULE_FUNT_IDX = "funct/update-indexes";
export const API_USERS = "users";
export const API_USER_TYPES = "user-types";
export const API_BANKS = "banks";
export const API_BANK_BRANCHS = "bank-branchs";
export const API_ROUTING_ROADS_WH = "routing-roads-wh";
// export const API_ROUTING_CUTOFF_TIME = "routing-cutoff-time";
export const API_TRANSPORT_TRIPS = "transport-trips";
export const API_TRANSPORT_TRIP_DETAILS = "transport-trip-details";
export const API_TRANSPORT_AUTO_CONFIG = "transport-auto-config";
export const API_TRANSPORT_BOOKINGS = "transport-bookings";
export const API_TRANSPORT_BOOKING_DETAILS = "transport-booking-details";
export const API_TRANSPORT_BOOKING_INCURREDS = "transport-booking-incurreds";
export const API_TRANSPORT_BOOKING_DRAFTS = "transport-booking-drafts";
export const API_EXPORT_TEMPLATE = `exports/template`;
export const API_EXPORT_IDENTIFIER_TEMPLATE = `report-identifier/template`;

export const API_IMPORT_TEMPLATE = `imports/by-template`;
export const API_DOWNLOAD = `downloads`;
export const API_DASHBOARD_VEHICLES= `dashboard-vehicles`;
export const API_REPORT_IDENTIFIER = "report-identifier";
export const API_TRIP_COST = "transport-trip-costs";
export const API_DSS = "dss";
export const API_EUP = "eup";
export const API_TRIP_EXPENSE= "transport_trips_expense";
export const API_STATEMENT_TRIP_COST= "statement_trip_costs";
export const API_TRANSPORT_TRIP_COST= "transport-trip-costs";
export const API_TRANSPORT_TRIP_CONFIG_KPI= "transport-trip-kpi-config";
export const API_VEHICLE_GROUP_WEIGHT_CONFIG = "vehicle-group-weight-config";
export const API_NOTIFICATION= "notification";
export const API_PARTNER_LOG= "partner_gps_logs";
export const TRANSPORT_TRIP_EXPORTED= "transport-trip-export";
///RMS
export const API_DEPARTEMEN_BLOCK= "department-blocks";
export const API_CAREER= "career";
export const API_DEPARTMENT= "departments";
export const API_ROLES_FUNCTION= "show-funct";
export const API_BANNER= "banner";
export const API_PAGES= "pages";
export const API_RECRUIMENT_TICKET= "recruitment_ticket";
export const API_WORKLOCATION= "work-location";
export const API_POSITION= "position";
export const API_POSTTYPE= "posttype";
export const API_POSTNEWS= "postnews";
export const API_EMAIL_FIELD= "emailfeild";
export const API_CHECKIN_YEP= "yep";
export const API_YEP_IMPORT_TEMPLATE = `yep/import`;
export const API_EMAIL= "email";
export const API_EDUCATION= "educations";
export const API_RECRUIMENT_NEW= "recruitmentnews";
export const API_RECRUIMENT_NEW_SEO= "recruitmentnewsSeo";
export const API_USERS_ORG = "userorg";
export const API_CANDIDATE= "candidate";
export const API_CANDIDATE_PROFILE= "candidateprofile";
export const API_LOGOUT= "auth/sign-out";
export const API_CANDIDATE_LOG= "candidatelog";
export const API_CANDIDATE_EVALUTE= "candidateevalute";
export const API_LEVEL= "level";
export const API_CANDIDATERESULT= "candidateresult";
export const API_STATUS_PROFILE= "candidatestatus";
export const API_POSTOFFICE = "GetSalesRegionInfoByID";

export const API_APPS = "apps";
export const API_USERAPPS = "user-apps";
export const API_ORG = "org-info";
export const API_USER_GROUPS = "user-groups";
export const API_USER_ERP = "user-orgs";
export const API_POST_OFFICE = "post-office";
export const API_SUB_DEPARTMENT = "sub-department";
export const API_BILLS = "bills";
export const API_WAREHOUSE = "wh-operation";
export const API_SERVICES = "services";
export const API_GOOD_TYPES = "goods-type";
export const API_PARTNERS = "partners";
export const API_PARTNER_ADDRESS = "partners/address";
export const API_CREATE_BILL = "bills";
export const API_CREATE_OPERATION_WH = "wh-operation";
export const API_GET_USERS = "users";
export const API_GET_WAREHOUSE_LIST = "warehouses";
export const API_SCAN_BILL_IN_OUT = "bills/check_in_out";
export const API_SCAN_ONE_BILL_IN_OUT = "wh-inout/add-bill";
export const API_COMPLETE_OPERATION_WH = "wh-operation/complete";
export const API_UPDATE_OPERATION_WH = "wh-operation";
export const API_PRINT_BILLS = "bills/print-bill";
export const SEARCHES = "bills/show-by-code";
export const API_DELETE_BILLS_IN_OUT = "wh-inout/remove-bills";
export const API_CANCEL_BILL = "bills/cancel-bill";
export const API_RETURN_BILL = "bills/return-bill";
export const API_SHOW_BY_CODE = "bills/show-by-code";
export const API_ZONES= "zones";


//TODO: PRICE
export const API_GET_PRICE_LIST = "price-list";
export const API_CREATE_PRICE_LIST = "price-list";
export const API_GET_PRICE_DETAIL_LIST = "price-list/detail";
export const API_CALCULATE_PRICE = "price-list/calculate";


export const API_GET_BANGKE_LIST = "debt-list";


export const API_GET_ZONES = "zones";

