/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { DEFAULT_FILTER, FLEX_FILTER_ELEMENTS } from "src/constants";
import "./style.scss";
import { _paramsFilter, columnsData, columnsDataApprove, columnsDataDetail, columnsDataXetDuyet } from "./data";
import TableStyled from "src/components/table/TableStyled";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { notifyError, notifySuccess } from "src/components/notification";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { localGetAccount } from "src/utils/localStorage";
import { Checkbox, Col, Form, Modal, Radio, Row } from "antd";
import moment from "moment";
import { convertNumberWithCommas } from "src/utils/helpers/functions/textUtils";
import TextArea from "antd/lib/input/TextArea";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const XetDuyetLuong = () => {
	let account: any = localGetAccount();
	const [data, setData] = useState<any>([]);
	const [paramsFilter, setParamsFilter] = useState<any>({ ..._paramsFilter });
	const [visible, setVisible] = useState<any>(false);
	const [params, setParams] = useState<any>({
		page: 1,
		limit: 10,
		candidate_status_id: 6,
		assigned_by: JSON.parse(account)?.user?.id
	});
	const [formRating] = Form.useForm();
	const [formApprove] = Form.useForm();
	const [level, setLevel] = useState<any>();
	const [loading, setLoading] = useState<any>(false);
	const [callback, setCallback] = useState<boolean>(false);
	const [total, setToal] = useState<number>(0);
	const [dataSyscTicket, setDataSyscTicket] = useState<any>([]);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [idCostsIncurred, setIdCostsIncurred] = useState<any>();
	const [visibleCostsIncurred, setVisibleCostsIncurred] = useState<any>(false);
	const [statusStatements, setStatusStatements] = useState<any>([]);
	const [statusPayment, setStatusPayment] = useState<any>([]);
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	// const [account, setAccount] = useState<any>({});
	const [dataCareer, setDataCareer] = useState<any>([]);
	const [dataStatus, setDataStatus] = useState<any>([]);
	const [dataProfile, setDataProfile] = useState<any>();
	const tieuchi = ["Chưa phù hợp", "Tạm chấp nhận", "Phù hợp", "Rất Phù hợp"];
	const [noidungkhac, setNoidungkhac] = useState("");
	const [noidungkhacE, setNoidungkhacE] = useState("");
	const [datcheck, setDatcheck] = useState("");
	const [datcheckE, setDatcheckE] = useState("");
	const [dinhhuong, setDinhhuong] = useState("");
	const [dinhhuongE, setDinhhuongE] = useState("");
	const [nhanxetchung, setNhanxetchung] = useState("");
	const [nhanxetchungE, setNhanxetchungE] = useState("");
	const [openModalLoadReport, setOpenModalLoadReport] = useState<boolean>(false);
	const [isSelectId, setIsSelectId] = useState<any>();
	const [datas, setDatas] = useState([
		{
			title: "Học vấn – Kinh nghiệm",
			data: [
				{
					title: "",
					datachild: [
						{
							id: 1,
							title: "Học vấn / Kiến thức chuyên môn",
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 2,
							title: "Kinh nghiệm làm việc - cùng ngành",
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 3,
							title: `Thâm niên, kỹ năng phù hợp 
							(Theo Mô tả CV`,
							activeTab: "",
							activeTab1: ""
						}
					]
				}
			]
		},
		{
			title: "Năng lực Hành vi – Giá trị cốt lõi",
			data: [
				{
					title: "1. Trách nhiệm",
					datachild: [
						{
							id: 4,
							title: `Ý thức trách nhiệm với công việc, 
							tinh thần hợp tác.  Làm việc hiệu quả 
							với thành viên khác để hoàn thành 
							mục tiêu`,
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 5,
							title: `Ứng viên có khả năng giải quyết vấn 
							đề, sự cố trong công việc. Có thể làm
							được việc “không thể thành có thể”, 
							tạo sự đột phá (có những trải nghiệm
							thực tế trong quá khứ).`,
							activeTab: "",
							activeTab1: ""
						}
					]
				},
				{
					title: "2. Trung thực",
					datachild: [
						{
							id: 6,
							title: `Trung thực, trân trọng lời nói`,
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 7,
							title: `Sử dụng ngôn ngữ lượng hoá, không đổ lỗi hoàn cảnh. Sẵn sàng đưa ra cam kết và thực hiện đúng cam kết. Ứng viên có khả năng tạo ảnh hưởng đến người khác.`,
							activeTab: "",
							activeTab1: ""
						}
					]
				},
				{
					title: "3. Chiến đấu",
					datachild: [
						{
							id: 8,
							title: `Yêu thích sự thành công, ứng viên có khả năng vượt qua khó khăn để tạo ra kết quả xuất sắc`,
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 9,
							title: `Tư duy cởi mở và luôn muốn đấu tranh để dẫn đầu. Không bao giờ nói không, không bao giờ bỏ cuộc`,
							activeTab: "",
							activeTab1: ""
						}
					]
				}
			]
		},
		{
			title: "Kỹ năng/ Yếu tố khác",
			data: [
				{
					title: "",
					datachild: [
						{
							id: 10,
							title: "Ngoại ngữ",
							activeTab: "",
							activeTab1: ""
						},
						{
							id: 11,
							title: "Phong cách ngoại hình",
							activeTab: "",
							activeTab1: ""
						}
					]
				}
			]
		},
		{
			title: "Đánh giá về động cơ phù hợp",
			data: [
				{
					title: "",
					datachild: [
						{
							id: 12,
							title:
								"Mức độ quan tâm đến công ty và CV ứng tuyển (Ứng viên đã tham khảo thông tin Công ty trên Website, xem qua giá trị cốt lõi)",
							activeTab: "",
							activeTab1: ""
						}
					]
				}
			]
		}
	]);
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	useEffect(() => {
		const initcareer = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getCareer({ limit: 100, status: "A" });
				setDataCareer(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
		const initstatus = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getStatusProfile({ limit: 100, status: "A" });
				setDataStatus(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initstatus();
	}, []);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getProfileListAcept(params);
				setData(res?.data);
				setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, [params, callback]); //eslint-disable-line
	const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
	};
	const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
	const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
	const costsIncurredDetail = (id: any) => {
		setIdCostsIncurred(id);
		setVisibleCostsIncurred(true);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};
	const handleOkPheduyet = async () => {
		const values = formApprove.getFieldsValue();
        if(values?.user_manager_result !== 0 && values?.user_manager_result !== 1) {
            return notifyError("Vui lòng chọn kết quả phê duyệt lương");
        }
        if(values?.user_manager_result === 0 && !values?.user_manager_note) {
            return notifyError("Vui lòng nhập lý do từ chối phê duyệt lương");
        }
		let params1 = {
			candidate_id: dataProfile?.id,
			candidate_status_id: 0,
			action_name: values?.user_manager_result === 0 ? `Từ chối phê duyệt lương` : `Đồng ý phê duyệt lương`
		};
		let params = {
			assigned_by:values?.user_manager_result === 0  ? 0 : 0,
			candidate_status_id: values?.user_manager_result === 0  ? 10 : 7,
            reject_reason: values?.user_manager_result === 0 ? values?.user_manager_note : "",
            reject_by: values?.user_manager_result === 0 ? JSON.parse(account)?.user?.id : 0,
		};
		try {
			const res1: any = await _apiNotSaga.updateCandidate(dataProfile.candidate_id, params);
			const res2: any = await _apiNotSaga.createCandidateLog(params1);
			setLoading(false);
			notifySuccess(res1.message);
            setOpenModalLoadReport(false);
			setCallback(!callback);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const handleLoadReport = async (id: any) => {
		setIsSelectId(id);
		const initp = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const ress: any = await _apiNotSaga.getCandidateDetails(id);
				setDataProfile(ress?.data);
				setOpenModalLoadReport(true);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initp();
		const initResult = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				let params = {
					candidate_id: id
				};
				setLoading(true);
				const ress: any = await _apiNotSaga.getCandidateResult(params);
				if (ress.data.length > 0) {
					formRating.setFieldsValue({
						...ress?.data[0],
						expected_working: moment(ress?.data[0]?.expected_working)
					});
					setLevel(ress?.data[0]?.level_name);
				}
				// setDataRe(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initResult();
		const initEvalute = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				let params = {
					candidate_id: id
				};
				setLoading(true);
				const ress: any = await _apiNotSaga.getCandidateEvalute(params);
				if (ress.data.length > 0) {
					// setIsRatingId(ress?.data[0].id);
					if (ress?.data[0]?.recruitment_eval) {
						let paramsss = JSON?.parse(ress?.data[0]?.recruitment_eval);

						setDatas(paramsss?.data);
						setDinhhuong(paramsss?.dinhhuong);
						setNhanxetchung(paramsss?.nhanxetchung);
						setDatcheck(paramsss?.datcheck);
						setNoidungkhac(paramsss?.noidungkhac);
					}
					if (ress?.data[0]?.specialize_eval) {
						let paramsss = JSON?.parse(ress?.data[0]?.specialize_eval);
						setDatas(paramsss?.data);
						setDinhhuongE(paramsss?.dinhhuong);
						setNhanxetchungE(paramsss?.nhanxetchung);
						setDatcheckE(paramsss?.datcheck);
						setNoidungkhacE(paramsss?.noidungkhac);
					}
				} else {
					// setIsRatingId(undefined);
				}
				// let paramsss = JSON?.parse(ress?.data?.recruitment_eval);
				// console.log("paramsss", paramsss);
				// setDataEvalute(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initEvalute();
	};
	return (
		<Loading spinning={loading}>
			<Modal
				className="tms-modal-confirm w-[900px]"
				title="Xét duyệt lương"
				width={900}
				open={openModalLoadReport}
				onOk={handleOkPheduyet}
				onCancel={() => setOpenModalLoadReport(false)}
			>
				<div id="page" className="w-full bg-white p-2 ">
					<div className="flex justify-between items-center p-2 border border-black w-full h-[120px] ">
						<div className="w-1/7">
							<img src="/logom.svg" className="w-[94px] h-[35px]" alt="logo" />
						</div>
						<div className="w-4/7">
							<p className="text-[20px] font-bold text-center">BẢN ĐÁNH GIÁ KẾT QUẢ PHỎNG VẤN</p>
						</div>
						<div className="2/7">
							<div className="flex flex-col">
								<p>Mã: NTL.HR.DI.01.FM05</p>
								<p>PB: </p>
								<p>NBH: </p>
							</div>
						</div>
					</div>
					<div className="flex justify-between items-center border border-black w-full py-2 divide-x divide-black mt-2">
						<div className="w-1/2 flex-col flex p-2">
							<p className="font-bold text-left">Họ tên ứng viên: {dataProfile?.candidate_name}</p>
							<div className="flex justify-between items-center w-full">
								<p className=" text-[14px] w-1/2">
									Ngày sinh: {dataProfile?.dob ? moment(dataProfile?.dob).format("DD/MM/YYYY HH:mm") : ""}
								</p>
								<p className=" text-[14px] w-1/2">
									Giới tính: {dataProfile?.gender ? (dataProfile?.gender === 1 ? "Nam" : "Nữ") : ""}
								</p>
							</div>
						</div>
						<div className="w-1/2 flex-col flex p-2">
							<p className="font-bold text-left">Vị trí ứng tuyển: {dataProfile?.position_name}</p>
							<div className="flex justify-between items-center w-full">
								<p className=" text-[14px] w-1/2">Phòng ban:</p>
								<p className="text-[14px] w-1/2">Cấp bậc:</p>
							</div>
						</div>
					</div>
					<p className="font-bold text-[20px] my-2">I. ĐÁNH GIÁ CỦA BỘ PHẬN PHỎNG VẤN</p>
					<div className="bg-orange-100  border-b divide-x border-black border-opacity-5 divide-black justify-between items-center flex w-full mt-2">
						<div className="w-1/2 ">
							<div className=" text-black font-bold text-[20px] text-center">Tiêu chí đánh giá</div>
						</div>
						<div className="w-1/2 flex justify-between divide-x divide-black">
							<div className="flex flex-col w-1/2 p-2 divide-y divide-black">
								<p className="text-center font-bold py-2">
									BP TUYỂN DỤNG <br /> (đánh giá)
								</p>
								<div className="w-full flex justify-between items-center p-2 divide-x h-[100px] divide-black">
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[14px] font-medium text-center">Chưa phù hợp</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[14px] font-medium text-center">Tạm chấp nhận</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[14px] font-medium text-center">Phù hợp</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[14px] font-medium text-center">Rất Phù hợp</div>
									</div>
								</div>
							</div>
							<div className="flex flex-col w-1/2 p-2 divide-y divide-black">
								<p className="text-center font-bold py-2">
									BP CHUYÊN MÔN <br /> (đánh giá)
								</p>
								<div className="w-full flex justify-between items-center p-2 divide-x h-[100px] divide-black">
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[14px] font-medium text-center">Chưa phù hợp</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[14px] font-medium text-center">Tạm chấp nhận</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[14px] font-medium text-center">Phù hợp</div>
									</div>
									<div className="w-1/4 flex justify-center items-center p-1 ">
										<div className=" text-black text-[14px] font-medium text-center">Rất Phù hợp</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full ">
						{datas.map((item, index) => (
							<div className="w-full flex flex-col ">
								<div className="bg-neutral-400 w-full h-[40px] flex justify-start items-center">
									<div className=" text-white text-[14px] font-medium  p-2">{item.title}</div>
								</div>
								{item.data.map((item1, index1) => (
									<div className="w-full flex flex-col divide-y divide-zinc-300">
										{item1.title !== "" && (
											<div className="bg-neutral-200 w-full h-[40px] flex justify-start items-center p-2">
												<p className=" text-black text-[13px] font-medium  ">{item1.title}</p>
											</div>
										)}
										{item1.datachild.map((item2, index2) => (
											<div key={index2} className="w-full flex flex-col p-2">
												<div className="w-full flex justify-between items-center">
													<div className="w-1/2 flex justify-start items-center p-2">
														<div className=" text-black text-[13px] font-medium text-left">{item2.title}</div>
													</div>
													<div className="w-1/2 p-2">
														<div className="flex justify-between items-center w-full">
															{tieuchi.map((item3, index3) => (
																<div className="w-1/8 flex justify-center items-center">
																	<div className=" text-black text-[13px] font-medium text-center ">
																		<Checkbox
																			checked={item2.activeTab === tieuchi[index3]}
																			// onChange={() => handleCheckboxChange(item2.id, index3)}
																		/>
																	</div>
																</div>
															))}
															{tieuchi.map((item3, index3) => (
																<div className="w-1/8 flex justify-center items-center">
																	<div className=" text-black text-[13px] font-medium  ">
																		<Checkbox
																			checked={item2.activeTab1 === tieuchi[index3]}
																			// onChange={() => handleCheckboxChange(item2.id, index3)}
																		/>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								))}
							</div>
						))}
						<div className="w-full flex flex-col p-2">
							<div className="w-full flex justify-between items-center  divide-x divide-zinc-300">
								<div className="w-1/2 flex justify-start items-center p-2">
									<div className=" text-black text-[13px] font-medium text-left">
										Định hướng công việc trong thời gian tới
									</div>
								</div>
								<div className="w-1/2 flex justify-between items-center divide-x divide-black">
									<div className="w-1/2 p-2">
										<p className="text-center">{dinhhuong}</p>
									</div>
									<div className="w-1/2 p-2">
										<p className="text-center">{dinhhuongE}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<p className="font-bold text-[20px] my-2">II. PHẦN NHẬN XÉT</p>
					<table className="table-fixed w-full border-collapse border border-slate-500 border-spacing-2 p-2">
						<thead>
							<tr>
								<th className="border border-slate-600 p-2">Nhận xét chung</th>
								<th className="border border-slate-600 p-2">
									{" "}
									<p className="font-bold text-left w-full p-2">BP TUYỂN DỤNG</p>
								</th>
								<th className="border border-slate-600 p-2">
									<p className="font-bold text-left  w-full">PB/BP CHUYÊN MÔN</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className="">
								<td className="p-2"></td>
								<td className="p-2">{nhanxetchung}</td>
								<td className="p-2">{nhanxetchungE}</td>
							</tr>
							<tr className="">
								<td className="p-2">Kết luận</td>
								<td className="p-2">
									<div className="h-[70px] flex flex-col">
										<div className="flex justify-start items-center">
											<Checkbox checked={datcheck === "Đạt" ? true : false}>Đạt</Checkbox>
											<Checkbox checked={datcheck === "Không Đạt" ? true : false}>Không Đạt</Checkbox>
											<Checkbox checked={datcheck === "Khác" ? true : false}>Khác</Checkbox>
										</div>
										<p className="">Ký, họ và tên: {dataProfile?.handle_by_name}</p>
										<p className="">Ngày: {moment(dataProfile?.updated_at).format('DD/MM/YYYY')}</p>
										
									</div>
								</td>
								<td className="p-2">
									<div className="h-[70px] flex flex-col">
										<div className="flex justify-start items-center">
											<Checkbox checked={datcheckE === "Đạt" ? true : false}>Đạt</Checkbox>
											<Checkbox checked={datcheckE === "Không Đạt" ? true : false}>Không Đạt</Checkbox>
											<Checkbox checked={datcheckE === "Khác" ? true : false}>Khác</Checkbox>
										</div>
										<p className="">Ký, họ và tên:</p>
										<p className="">Ngày:___/___/___</p>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					{/* <div className="flex justify-between items-center border border-black w-full py-2 divide-x divide-black mt-2">
						<div className="w-2/12 flex-col flex p-2">
							<p className="font-bold text-left h-[150px] w-full">Nhận xét chung</p>
							<p className="font-bold text-left h-[70px]  w-full">Kết luận</p>
						</div>
						<div className="w-5/12 flex-col flex p-2 ">
							<div className="h-[150px] flex flex-col justify-start">
								<p className="text-center font-bold">BP TUYỂN DỤNG</p>
								<p className="text-center py-2">{nhanxetchung}</p>
							</div>
							<div className="h-[70px] flex flex-col">
								<div className="flex justify-start items-center">
									<Checkbox checked={datcheck === "Đạt" ? true : false}>Đạt</Checkbox>
									<Checkbox checked={datcheck === "Không Đạt" ? true : false}>Không Đạt</Checkbox>
									<Checkbox checked={datcheck === "Khác" ? true : false}>Khác</Checkbox>
								</div>
								<p className="">Ký, họ và tên:</p>
								<p className="">Ngày:___/___/___</p>
							</div>
						</div>
						<div className="w-5/12 flex-col flex p-2">
							<div className="h-[150px] flex flex-col justify-start">
								<p className="text-center font-bold">PB/BP CHUYÊN MÔN</p>
								<p className="text-center py-2">{nhanxetchungE}</p>
							</div>
							<div className="h-[70px] flex flex-col">
								<div className="flex justify-start items-center">
									<Checkbox checked={datcheckE === "Đạt" ? true : false}>Đạt</Checkbox>
									<Checkbox checked={datcheckE === "Không Đạt" ? true : false}>Không Đạt</Checkbox>
									<Checkbox checked={datcheckE === "Khác" ? true : false}>Khác</Checkbox>
								</div>
								<p className="">Ký, họ và tên:</p>
								<p className="">Ngày:___/___/___</p>
							</div>
						</div>
					</div> */}
					<p className="font-bold text-[20px] my-2">II. QUYẾT ĐỊNH CUỐI CÙNG</p>
					<table className="table-fixed w-full border-collapse border border-slate-500 border-spacing-2 p-2">
						<thead>
							<tr>
								<th className="border border-slate-600 p-2">Mức lương thỏa thuận</th>
								<th className="border border-slate-600 p-2">
									{" "}
									<p className="font-bold text-left w-full p-2">
										Trưởng bộ phận <span>(Đề xuất)</span>{" "}
									</p>
								</th>
								<th className="border border-slate-600 p-2">
									<p className="font-bold text-left  w-full">
										GĐ QTNNL <span>(Xét duyệt)</span>
									</p>
								</th>
								<th rowSpan={1} className="border border-slate-600 p-2">
									<p className="font-bold text-left  w-full">
										Tổng Giám đốc/GĐ Điều hành (*)
										<span>(Phê chuẩn)</span>
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className="">
								<td className="p-2">Chức danh</td>
								<td className="p-2">{dataProfile?.position_name}</td>
								<td className="p-2"></td>
								<td rowSpan={8}></td>
							</tr>
							<tr className="">
								<td className="p-2">Cấp bậc</td>
								<td className="p-2">{level}</td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td className="p-2">Lương thử việc</td>
								<td className="p-2">{convertNumberWithCommas(formRating.getFieldValue("probation_salary") || 0)}</td>
								<td className="p-2"></td>
							</tr>

							<tr className="">
								<td className="p-2">Lương sau thử việc</td>
								<td className="p-2">
									{convertNumberWithCommas(formRating.getFieldValue("after_probation_salary") || 0)}
								</td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td className="p-2">Phụ cấp khác (nếu có)</td>
								<td className="p-2">{convertNumberWithCommas(formRating.getFieldValue("other_allowances") || 0)}</td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td className="p-2">Mức đóng bảo hiểm</td>
								<td className="p-2"></td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td className="p-2">(Ký, Họ tên)</td>
								<td className="p-2"></td>
								<td className="p-2"></td>
							</tr>
							<tr className="">
								<td colSpan={3} className="p-2">
									Thời gian vào làm việc dự kiến: Ngày{" "}
									{moment(formRating.getFieldValue("expected_working")).format("DD")} tháng{" "}
									{moment(formRating.getFieldValue("expected_working")).format("MM")} năm{" "}
									{moment(formRating.getFieldValue("expected_working")).format("YYYY")}
								</td>
							</tr>
						</tbody>
					</table>
					<p>(*) Nếu đã có ủy quyền từ TGĐ, GĐ Chi nhánh sẽ duyệt mức thu nhập của NV chi nhánh trực thuộc</p>
					<p className="font-bold text-[24px]">Quyết đinh của bạn?</p>
					<Form name="formApprove" layout="vertical" form={formApprove}>
						<Row gutter={[8, 8]}>
							<Col md={24} xs={24} sm={24}>
								<Form.Item label="" name="user_manager_result">
									<Radio.Group>
										<Radio value={1}> Đồng ý </Radio>
										<Radio value={0}> Từ chối </Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							{/* {formApprove.getFieldValue("user_manager_result") === 0 && ( */}
							<Col md={24} xs={24} sm={24}>
								<Form.Item label="Lý do" name="user_manager_note">
									<TextArea rows={6} placeholder="Lý do" />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
			<div className="contentSection">
				<div className="top-0 contentSection-filter">
					<FlexFilter
						onSearch={(v) => onSearch(v)}
						manipulation={[
							{
								paramsName: "btnAdd",
								icon: <PlusOutlined />,
								text: "Tạo mới"
							}
							// {
							// 	paramsName: "btnSysc",
							// 	icon: <TransactionOutlined />,
							// 	text: "Tạo ticket thanh toán"
							// }
						]}
						elements={[
							{
								...FLEX_FILTER_ELEMENTS.input,
								placeholder: "Nhập tên ứng viên",
								label: "Tên ứng viên",
								paramsName: "search"
							},

							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Ngành nghề",
								placeholder: "Chọn ngành nghề",
								width: 160,
								options: {
									data: dataCareer,
									keyOption: "id",
									valueOption: "id",
									nameOption: "career_name"
								},
								paramsName: "career_id"
							},

							// {
							// 	...FLEX_FILTER_ELEMENTS.select,
							// 	label: "Trạng thái",
							// 	placeholder: "Chọn trạng thái",
							// 	width: 160,
							// 	options: {
							// 		data: dataStatus,
							// 		keyOption: "id",
							// 		valueOption: "id",
							// 		nameOption: "candidate_status"
							// 	},
							// 	paramsName: "candidate_status_id"
							// },
							{
								...FLEX_FILTER_ELEMENTS.createdRange,
								label: "Ngày nộp hồ sơ",
								initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
								initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
								width: 250
							},
							{
								...FLEX_FILTER_ELEMENTS.btnSearch,
								icon: <SearchOutlined />
							}
							// { ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined /> }
						]}
					/>
				</div>
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={data}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: params.page,
							showSizeChanger: true,
							defaultPageSize: params.limit,
							total: total || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
						scroll={{
							x: data.length > 0 ? (columnsData({}).length - 1) * 100 : null,
							y: "calc(70vh - 80px)"
						}}
						isRowLight={true}
						columns={
							columnsDataXetDuyet({
								onEdit,
								onCancel,
								pushUpApproval,
								onCreateTicket,
								user: account,
								handleLoadReport
							}) as any
						}
					/>
				</div>
			</div>
		</Loading>
	);
};

export default XetDuyetLuong;
