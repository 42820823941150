import { Form, Input, Select, Button, InputNumber, Col, Row } from "antd";
import "./style.scss";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { FLEX_FILTER_ELEMENTS } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { _paramsFilter, columnsData } from "./data";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import SvgBag from "src/assets/svg/SvgBag";
import FormSelectAntd from "src/components/formStyled/FormSelectAntd";
import { defaultStyles } from "src/components/formStyled/defaultStyles";
import FormSelect from "src/components/form/FormSelect";
import { getGoodTypes, getProvinces, getServices } from "src/services/actions/global.actions";
import { AppState } from "src/types";

const { Option } = Select;
const ReceiverInfo = ({ form }: any) => {
	// const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [provinces, setProvinces] = useState<any[]>([]);
	const [districts, setDistricts] = useState<any[]>([]);
	const [wards, setWards] = useState<any[]>([]);
	const [receiverProvinceId, setReceiverProvinceId] = useState(0);
	const [receiverDistrictId, setReceiverDistrictId] = useState(0);
	const { stateProvinces, stateGoodTypes, stateServices } = useSelector((e: AppState) => e.globalReducer);
	useEffect(() => {
		dispatch(getProvinces());
		dispatch(getGoodTypes());
		dispatch(getServices());
	}, []);

	// useEffect(() => {
	// 	const getDistrict = async () => {
	// 		try {
	// 			const district: any = await _apiNotSaga.getDistricts({ province_id: receiverProvinceId });
	// 			if (district.success) {
	// 				setDistricts(district.data);
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	};
	// 	if (receiverProvinceId > 0) {
	// 		getDistrict();
	// 	}
	// 	return () => {
	// 		setDistricts([]);
	// 	};
	// }, [receiverProvinceId]);

	// useEffect(() => {
	// 	const getWards = async () => {
	// 		try {
	// 			const ward: any = await _apiNotSaga.getWards({ district_id: receiverDistrictId });
	// 			if (ward.success) {
	// 				setWards(ward.data);
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	};
	// 	if (receiverDistrictId > 0) {
	// 		getWards();
	// 	}
	// 	return () => {
	// 		setDistricts([]);
	// 	};
	// }, [receiverDistrictId]);
	const onChangeProvince = async (e: any) => {
		form.setFieldsValue({
			r_province_name: (stateProvinces?.data || []).find((item: any) => item.id === e)?.province_name,
			r_district_id: undefined,
			r_ward_id: undefined,
			r_district_name: undefined,
			r_ward_name: undefined
		});
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			setDistricts(district.data);
		}
	};
	const onChangeDistrict = async (e: any) => {
		form.setFieldsValue({
			r_ward_id: undefined,
			r_ward_name: undefined,
			r_district_name: districts.find((item: any) => item.id === e)?.district_name
		});
		const ward: any = await _apiNotSaga.getWards({ district_id: e });
		if (ward.success) {
			setWards(ward.data);
		}
	};
	return (
		<div>
			<h3 style={{ fontWeight: 600 }}>Thông tin người nhận</h3>
			<Row gutter={[12, 12]}>
				<Col md={24} xs={24} sm={24}>
					<Form.Item
						label="Họ tên"
						name="r_name"
						rules={[{ required: true, message: "Vui lòng nhập họ tên người nhận" }]}
					>
						<Input placeholder="Nhập họ tên người nhận" className="w-full" />
					</Form.Item>
				</Col>
				<Col md={12} xs={12} sm={12} style={{ paddingLeft: 8 }}>
					<Form.Item
						label="Số điện thoại"
						name="r_phone"
						rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}
					>
						<Input placeholder="Nhập Sđt người nhận" className="w-full" />
					</Form.Item>
				</Col>
				<Col md={12} xs={12} sm={12} style={{ paddingTop: 2 }}>
					<Form.Item label="Số điện thoại 2" name="r_phone_2" >
						<Input placeholder="Nhập Sđt 2 người nhận" className="w-full" />
					</Form.Item>
				</Col>
			</Row>

			<div className="flex">
				<Form.Item style={{ display: "none" }} name="r_province_name" />
				<Form.Item style={{ display: "none" }} name="r_district_name" />
				<Form.Item style={{ display: "none" }} name="r_ward_name" />
				<Col md={8} xs={8} sm={8} style={{ paddingLeft: 0 }}>
					<FormSelect
						name="r_province_id"
						label="Tỉnh thành"
						dataSource={stateProvinces?.data || []}
						placeholder="Chọn tỉnh thành"
						slOption={{
							name: "province_name",
							value: "id"
						}}
						required={true}
						// disabled={disableAddress}
						onChange={onChangeProvince}
						showSearch
						optionFilterProp="children"
					/>
				</Col>
				<Col md={8} xs={8} sm={8}>
					<FormSelect
						name="r_district_id"
						label="Quận huyện"
						dataSource={districts}
						placeholder="Chọn quận huyện"
						slOption={{
							name: "district_name",
							value: "id"
						}}
						required={true}
						// required={!disableAddress}
						// disabled={disableAddress}
						onChange={onChangeDistrict}
						showSearch
						optionFilterProp="children"
					/>
				</Col>
				<Col md={8} xs={8} sm={8} style={{ paddingRight: 0 }}>
					<FormSelect
						name="r_ward_id"
						label="Phường xã"
						dataSource={wards}
						placeholder="Chọn phường xã"
						slOption={{
							name: "ward_name",
							value: "id"
						}}
						required={true}
						// required={!disableAddress}
						// disabled={disableAddress}
						onChange={(e) => {
							form.setFieldsValue({
								r_ward_name: (wards || []).find((item: any) => item.id === e)?.ward_name
							});
						}}
						showSearch
						optionFilterProp="children"
					/>
				</Col>
			</div>

			<Form.Item label="Địa chỉ" name="r_address" rules={[{ required: true, message: "Vui lòng nhập địa chỉ" }]}>
				<Input placeholder="Nhập địa chỉ, VD: 28/23/22 Nguyễn Thị Nhỏ" className="w-full" />
			</Form.Item>

			<Form.Item label="Ghi chú" name="note">
				<Input.TextArea placeholder="Nhập ghi chú, VD: Cho xem hàng nhưng không cho thử hàng." className="w-full" />
			</Form.Item>
		</div>
	);
};

export default ReceiverInfo;
