import { Button, Dropdown, Menu, Switch, Tag } from "antd";
import moment from "moment";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgPen from "src/assets/svg/SvgPen";
import { createUser, userInfo } from "src/components/commonRender/renderData";
import { TICKET_URL } from "src/services/api/config";
import styled from "styled-components";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({ updateStatus, getDetail, createPost }: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				},
				r?.news_id
					? null
					: {
							key: "edit",
							label: (
								<Button onClick={() => createPost(r?.id)}>
									<SvgPen fill="black" />
									<span>Tạo tin tuyển dụng</span>
								</Button>
							)
					  }
			]}
		/>
	);
	return [
		{
			title: "Ticket",
			key: "ticket_id",
			fixed: "left",
			width: 140,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<a target="_blank" href={`${TICKET_URL}tickets/${record?.ticket_id}`}>
							<div className="text-blue-600 text-[13px] font-normal  underline leading-snug">#{record?.ticket_id}</div>
						</a>
						<div className="text-black text-[13px] font-normal   leading-snug">
							NVTD: {record.user_erp}-{record?.full_name}
						</div>
						<Tag color={record?.qty_actual >= record?.qty ? "success" : "error"}>
							{record?.qty_actual >= record?.qty ? "Đạt" : "Chưa đạt"}
						</Tag>
					</div>
				);
			}
		},
		{
			title: "Khu vực",
			width: 180,
			key: "zone_name",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.zone_name}</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Khối:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.department_block_name}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Chi nhánh:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.org_name}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "NV chịu trách nhiệm",
			width: 250,
			key: "zone_name",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
							{record?.user_erp} - {record?.full_name}
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">email:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.email}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">SĐT:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.phone}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Phòng ban",
			width: 180,
			key: "org_name",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
							{record?.department_name}
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngành nghề:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.career_name}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Chức danh:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.position_name}
							</span>
						</div>
					</div>
				);
			}
		},

		{
			title: "Bộ phận",
			key: "career_name",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						{/* <div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.phongban}</div> */}
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngành nghề:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.career_name}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Bộ phận:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.position_name}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Tin tuyển dụng",
			key: "career_name",
			width: 200,
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<a
							href={`/create-post/${record?.news_id}`}
							className="text-link text-opacity-90 text-[13px] font-normal  leading-snug"
						>
							{record?.thread}
						</a>
						{record?.news_id && (
							<div className="flex">
								<Tag color="success">
									{record?.news_status === "O"
										? "Tin mới"
										: record?.news_status === "D"
										? "Nháp"
										: record?.news_status === "W"
										? "Chờ duyệt"
										: record?.news_status === "A"
										? "Đã duyệt"
										: "Ẩn"}
								</Tag>
								{record?.is_popup === 1 && <img src="/pin.svg" style={{ width: 20, height: 20 }} />}
								{record?.is_hot === 1 && <img src="/fire.svg" style={{ width: 20, height: 20 }} />}
							</div>
						)}
						{/* <div>
							<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Bộ phận:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.position_name}
							</span>
						</div> */}
					</div>
				);
			}
		},
		{
			title: "Thời gian",
			width: 250,
			// dataIndex: "qty",
			key: "public_at",
			// align: "right",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-end items-start flex-col">
						<div>
							<span className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">Bắt đầu:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.public_at).format("DD/MM/YYYY HH:mm")}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">Kết thúc:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.expired_at).format("DD/MM/YYYY HH:mm")}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "KQ/Chỉ tiêu",
			width: 100,
			// dataIndex: "qty",
			key: "qty",
			align: "right",
			render: (record: any, index: number) => {
				return (
					<div className="flex justify-end items-start">
						<div>
							<span className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">
								{record?.qty_actual}/
							</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug"> {record?.qty}</span>
						</div>
					</div>
				);
			}
		},

		{
			title: "Thao tác",
			dataIndex: "action",
			key: "action",
			align: "center",
			width: 100,
			fixed: "right",
			render: (v: string, r: any) => {
				return (
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(r)} trigger={["hover"]} placement="bottomRight" arrow>
							{/* <Dropdown 
						menu={{ items: items(r) }} 
						overlayClassName="dropdown-css" trigger={["hover"]} placement="bottomRight" arrow> */}
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
